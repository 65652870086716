import './App.css';
import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from "@web3modal/ethereum";

import { useWeb3ModalTheme, Web3Modal } from "@web3modal/react";

import { configureChains, createClient, WagmiConfig } from "wagmi";

import { goerli, mainnet } from "wagmi/chains";
import Mint from './mint';
import { useEffect } from 'react';
import { PROJECTID } from './CONSTANT';

function App() {
  const chains = [mainnet];
  const projectId=PROJECTID;
  const { setTheme } = useWeb3ModalTheme()
// Wagmi client
const { provider } = configureChains(chains, [
  walletConnectProvider({ projectId}),
]);
const wagmiClient = createClient({
  autoConnect: false,
  connectors: modalConnectors({ appName: "KlubList", chains }),
  provider,
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);

useEffect(()=>{
  setTheme({themeMode:'dark',themeColor:'green',themeBackground:'themeColor'})
},[setTheme])
  return (
    <>
      <WagmiConfig client={wagmiClient}>
        <Mint />
      </WagmiConfig>

      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
      />
    </>
  );
}

export default App;
