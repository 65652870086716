import { BigNumber } from "ethers";
import { useEffect } from "react";
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from "wagmi";
import { ADDRESS } from "./CONSTANT";

function MintBtn({price,type,amount,setStartProcess,setReason,setHasError,setSuccess}){
    const { config,  error: prepareError, isError: isPrepareError, } = usePrepareContractWrite({
        address: ADDRESS,
        abi: [
            {
                "inputs": [
                    {
                        "internalType": "uint256",
                        "name": "_type",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    }
                ],
                "name": "claimTokens",
                "outputs": [],
                "stateMutability": "payable",
                "type": "function"
            },
        ],
        overrides: {
          value: BigNumber.from(''+(parseInt(price[type]) * amount)),
        },
        functionName: 'claimTokens',
        args: [BigNumber.from(type),BigNumber.from(amount)],
        enabled: Boolean(amount),
      })
      
      const {data, write}= useContractWrite(config)
      const { isLoading, isSuccess } = useWaitForTransaction({
        hash: data?.hash
      })
      useEffect(()=>{
        setStartProcess(isLoading)
      },[isLoading])
      useEffect(()=>{
        setSuccess(isSuccess)
      },[isSuccess])
      useEffect(()=>{
        setHasError(isPrepareError)
        setReason(prepareError?.reason)
      },[isPrepareError])
      return (<> <button
        className='mint-btn'
        type='button'
        disabled={!write}
        onClick={()=>write && write()}
      >
        <span>{isLoading?'Minting...':'Mint'}</span>
      </button></>)
}
export default MintBtn;