import { useWeb3Modal, Web3Button } from "@web3modal/react";
import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import Web3 from "web3";
import { ABI, ADDRESS, CMENV, CROSSMINT, INFURA } from "./CONSTANT";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import MintBtn from "./MintBtn";
function Mint(){
  const [quant, setQuant] = useState(1)
  const [type, setType] = useState(0);
  const [available, setAvailable] = useState([777,77])
  const [startProcess, setStartProcess] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [reason, setReason] = useState('')
  const [price, setPrice] = useState([177700000000000000,990000000000000000])
  const [cost, setCost] = useState([177700000000000000,990000000000000000])
  const [claimStarted, setClaimStarted] = useState(false)
  const { address, isConnected } = useAccount()
  const { isOpen } = useWeb3Modal();
  const web3 = new Web3(
    new Web3.providers.HttpProvider(INFURA)
  )
  let getAvailableFirst = async () => {
    
    const KLP = new web3.eth.Contract(ABI, ADDRESS)
    const claimPrice = await KLP.methods.getClaimPrice().call()
    if (claimPrice) {
      setPrice(claimPrice)
      setCost(claimPrice)
    }
    const startClaim = await KLP.methods.startClaim().call()
    setClaimStarted(startClaim)
    const remaining = await KLP.methods.getRemaining().call()
    setAvailable(remaining)
  }
  let handleChange = (event) => {
    let value = parseInt(event.target.value)
    if (value >= 1 && value <= 10) {
      setQuant(value)
      setCost([value * price[0],value * price[1]])
    } else if (value === '') {
      setQuant(1)
      setCost([price[0],price[1]])
    }
  }
  const onChangeValue =(event)=>{
    setType(parseInt(event.target.value));
  }
  useEffect(() => {
    getAvailableFirst()
    setTimeout(()=>{
        document.querySelector('w3m-core-button').shadowRoot.querySelector('w3m-connect-button').shadowRoot.querySelector('button').setAttribute('style', 'background-color: #54F98D!important');
    },1500)
  }, []);
  useEffect(() => { 
       console.log('Updated State', address,isConnected)
    }, [isOpen])
    return(
        <div className="minting-dapp-widget">
      <div className='mint-box'>
        <h2 className="color-klub">Step 1: Select Pass Type</h2>
        <div onChange={onChangeValue}>
            <label className="container-type" ><input type="radio" value="0" name="type" defaultChecked={type === 0} /><span className="checkmark"></span> KlubList Mint Pass</label>
            <label className="container-type"><input type="radio" value="1" name="type" defaultChecked={type === 1}/><span className="checkmark"></span> KlubList “Me+10” Mint Pass</label>
        </div><br/>
        <div className='main-box'>
        <h2 className="color-klub">Step 2: Select Quantity</h2>
          <div className='quantity-box'>
            <input
              type='number'
              id='quantity'
              value={quant}
              onChange={handleChange}
              min='1'
              max='10'
              maxLength='2'
            />
            <br />
            <span>Max 10</span>
          </div>
          <div className='cost-box'>
            <h3>Cost: {web3.utils.fromWei('' + price[type])} ETH + Gas</h3>
            <h3>Total Cost: {web3.utils.fromWei('' + cost[type])} ETH + Gas</h3>
          </div>
          <h2 className="color-klub">Step 3: Mint with wallet or Credit Card</h2>
          <div className='action-btns'>
            {claimStarted ? (  <>
           {(address || isConnected) ? (
              <>
                {available === 0 ? (
                  <div>
                    <button className='mint-btn' type='button' disabled>
                      <span>Sold Out </span>
                    </button>
                  </div>
                ) : (
                  <div style={{display:'flex',margin: '0 auto', width:'fit-content'}}>
                   <MintBtn setSuccess={setSuccess} setStartProcess={setStartProcess} setReason={setReason} setHasError={setHasError} price={price} amount={quant} type={type}/>  <h2 style={{display:'inline',margin:'0 15px', paddingTop: '6px'}}>OR</h2> <CrossmintPayButton
                    clientId={CROSSMINT}
                    className="my-custom-crossmint-button"
                    mintConfig={{
                        amount: quant,
                        totalPrice: web3.utils.fromWei('' + cost[type]),
                        _type:type
                    }}
                  /></div>
                )}
              </>
            ) : (
              <div style={{display:'flex',margin: '0 auto', width:'fit-content'}}>
                 <Web3Button /> <h2 style={{display:'inline',margin:'0 15px', paddingTop: '6px'}}>OR</h2> <CrossmintPayButton
                    clientId={CROSSMINT}
                    className="my-custom-crossmint-button"
                    mintConfig={{
                        amount: quant,
                        totalPrice: web3.utils.fromWei('' + cost[type]),
                        _type:type
                    }}
/>
              </div>
            )}</>):(
                <><span  className='red'>Claim not started yet.</span></>
            )}
            <div>
              {startProcess && (
                <div className='mint-loader'>
                  <div className='loader'></div>
                </div>
              )}
            </div>
            <div style={{marginTop:'10px'}} className='red'>{hasError? reason:''}</div>
            <div style={{marginTop:'10px'}} className='green'>{success? 'Minted Successfully':''}</div>
          </div>
        </div>
      </div>
        </div>
    )
}
export default Mint;